// @flow

import {
  text,
  commonTextStyle,
  size,
  bold,
  medium,
  regular,
  light,
} from './text.js';
import { colors } from './colors.js';

export const themeVariables = {
  fontFamily:
    // BlinkMacSystemFont is broken on Chrome 81
    // Roboto is broken too on macOS
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1057654
    // "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    "-apple-system, 'Segoe UI', Helvetica, Arial, sans-serif",
  borderRadius: {
    paper: 8,
    control: 4,
  },
};

const textColor = (key: $Keys<typeof colors>): { ... } => ({
  color: colors[key],
});

const bgColor = (key: $Keys<typeof colors>): { ... } => ({
  backgroundColor: colors[key],
});

export const palette = {
  primary: {
    main: '#3454d1',
  },
  secondary: {
    main: '#DD435A',
  },
  text: {
    primary: colors.highText,
    secondary: colors.mediumText,
    disabled: colors.disabledText,
    hint: colors.disabledText,
  },
};

export const theme = {
  // system-components specific theme here
  colors: {
    ...colors,
    primary: palette.primary,
    secondary: palette.secondary,
    background: { paper: '#fff', default: '#fafafa', card: '#e1e6f9' },
    success: '#309d58',
    danger: colors.red400,
  },
  text: {
    ...text,
    size,
    font: {
      light,
      regular,
      medium,
      bold,
    },
    subtitle0: {
      ...commonTextStyle,
      fontWeight: medium,
      fontSize: (size(18): string),
      lineHeight: (size(26): string),
    },
    body0: {
      ...commonTextStyle,
      fontWeight: light,
      fontSize: (size(18): string),
      lineHeight: (size(26): string),
    },
    // custom variants
    link: {
      display: 'block',
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'inherit',
      ':hover': {
        opacity: 0.85,
      },
    },
    linkToggle: {
      cursor: 'pointer',
      textDecoration: 'none',
      appearance: 'none',
      WebkitTapHighlightColor: 'transparent',
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      display: 'inline-block',
      fontWeight: 500,
      color: colors.primaryMain,
      ':hover': {
        opacity: 0.85,
      },
    },
    strongCaption: {
      ...commonTextStyle,
      fontWeight: medium,
      fontSize: (size(12): string),
      lineHeight: 1.66,
    },
    icon: {
      display: 'inline-block',
      fontSize: 'inherit',
      verticalAlign: 'middle',
      marginLeft: 2,
      marginRight: 2,
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      // wordWrap: 'normal' probably?
    },
  },
  shape: {
    borderRadius: 6,
  },
  breakpoints: [`768px`, `1280px`, `1920px`],
  fonts: {
    sans: themeVariables.fontFamily,
    mono: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  },
  maxWidths: ['100%', 960, 1200],
  shadows: {
    elevation3:
      '0px 1px 8px 0px rgba(65,65,139, 0.08), 0px 3px 4px 0px rgba(65,65,139, 0.06), 0px 3px 3px -2px rgba(65,65,139, 0.04)',
  },

  // style getters
  textColor,
  bgColor,
};
