// @flow

// How to log in /realadvisor.aggregator
// ----------------------------------------------------------------------------------------
//
// Never use console.log() / console.info() / console.error() / etc!
//
// From a React component:
//
//    import { useLog } from '../../hooks/log';
//    const Component = () => {
//       const logger = useLog();
//       logger.info(...);
//    }
//
//
// If you have access to `UserData`:
//
//    import { makeLogger } from '../log';
//    const logger = makeLogger(userData.ssrCloudLogsTrace);
//    logger.info(...);
//
//
// If you have access to `req` that is currently being handled:
//
//    import { makeLogger, getCloudLogsTrace } from '../log';
//    const logger = makeLogger(getCloudLogsTrace(req));
//    logger.info(...);
//
//
// If nothing above describes your case:
//
//    import { makeLogger } from '../log';
//    const logger = makeLogger();
//    logger.info(...);

import type { Severity } from '@realadvisor/google-cloud-logs';

export const log = (
  options: {| severity?: Severity, trace: ?string |},
  data: $ReadOnlyArray<any>,
): void => {
  const severity = options.severity ?? 'INFO';

  if (typeof window !== 'undefined' || process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console[severity.toLowerCase()](...data);
    return;
  }

  // Code below is compiled away by Webpack for the browser build

  import('@realadvisor/google-cloud-logs').then(({ formatLog }) => {
    console.info(formatLog({ severity, trace: options.trace }, data));
  });
};

export type Logger = {|
  traceForSupport: string,
  info: (...data: $ReadOnlyArray<any>) => void,
  warn: (...data: $ReadOnlyArray<any>) => void,
  error: (...data: $ReadOnlyArray<any>) => void,
  debug: (...data: $ReadOnlyArray<any>) => void,
|};

export const makeLogger = (_trace: ?string): Logger => {
  // In browser trace never makes sense.
  // We make sure it's not used here, to allow this pattern: makeLogger(userData.ssrCloudLogsTrace)
  const trace = typeof window === 'undefined' ? _trace : null;

  let traceForSupport = '';
  if (trace != null) {
    const parts = trace.split('/');

    // don't want to have full trace here
    // to avoid exposing info about our infrastructure unnecessarily
    traceForSupport = `trace_for_support:${parts[parts.length - 1]}`;
  }

  return {
    // this can be added to a error message that an end user sees
    traceForSupport,
    info: (...data) => log({ trace, severity: 'INFO' }, data),
    warn: (...data) => log({ trace, severity: 'WARN' }, data),
    error: (...data) => log({ trace, severity: 'ERROR' }, data),
    debug: (...data) => log({ trace, severity: 'DEBUG' }, data),
  };
};

export const getCloudLogsTrace = (
  req: http$IncomingMessage<any>,
  project: ?string = process.env.GCP_PROJECT,
): ?string => {
  const traceContext = req.headers['x-cloud-trace-context'];
  if (project == null || traceContext == null) {
    return null;
  }
  return `projects/${project}/traces/${traceContext.split('/')[0]}`;
};
